import { memo } from 'react';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
import QuoteValueItem from '~/modules/investment-consultant/quoteValueBody/QuoteValueBody';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
/** 可以掛載於TopBar的報價跑馬燈 */
const MarqueeSymbolBody = memo(function SymbolMarquee(props) {
    const theme = useThemeStore(s => s.theme);
    const quote = props.quote;
    return (<classes.Root css={baseStyled}>
      <classes.container className={theme}>
        <classes.item justify='center' column={1} row={1}>
          <QuoteValueItem.arrow.Display quote={quote} changeType={'closeChange'}/>
          <SymbolName symbol={quote?.symbol}/>
          {!quote?.symbol.includes('-') && quote?.symbol}
        </classes.item>
        <classes.item justify='start' column={2} row={1}>
          <QuoteValueItem.change.Display quote={quote} changeType={'closeChange'}/>
        </classes.item>
        <classes.item justify='center' column={1} row={2} css={css `
            font-size: 16px;
          `}>
          <QuoteValueItem.ohlc.Display quote={quote} ohlcType='close'/>
        </classes.item>
        <classes.item justify='start' column={2} row={2}>
          <QuoteValueItem.change.Display quote={quote} changeType={'closeChangePercent'} unit={'%'}/>
        </classes.item>
      </classes.container>
    </classes.Root>);
});
const classes = {
    Root: styled.div `
    width: 100%;
    height: 100%;
  `,
    container: styled.div `
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: 50% 50%;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
  `,
    item: styled.div `
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => props.justify};
    margin: 0px;
    grid-column: ${props => props.column};
    grid-row: ${props => props.row};
  `,
};
const baseStyled = css `
  ${classes.Root} {
  }
  ${classes.container} {
    &.dark {
      background-color: #1d1e29;
      &:hover {
        background-color: #2d2e39;
      }
    }

    &.light {
      background-color: #f1f1f1;
      &:hover {
        background-color: #eeeeee;
      }
    }
  }
  ${classes.item} {
  }
`;
export default {
    Display: MarqueeSymbolBody,
    classes,
};
