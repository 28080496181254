import { css } from '@emotion/react';
import OptionChart from '../daddy960_opkevin/component/OptionChart';
import MarqueeSymbolBody from '../datatree/component/MarqueeSymbolBody';
export const stanli_initStyling = (templateProps) => {
    templateProps.globalCssset = css `
    #__next {
      ${MarqueeSymbolBody.classes.container} {
        background-color: #f8f8f8;
        border: 1px solid #cccccc;
        &:hover {
          background-color: #f1f1f1;
        }
      }

      ${(OptionChart.classesFill.storkFill = '#454545')}
      ${(OptionChart.classesFill.lineFill = '#ffaa00')}
      ${(OptionChart.classesFill.referenceDotFill = '#ffaa00')}
      ${(OptionChart.classesFill.gridFill = '#cccccc')}
    }
  `;
};
