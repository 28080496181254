import { Select } from '@mantine/core';
import { memo, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { fill } from '~/modules/AppLayout/FlexGridCss';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import dayAPI from '~/utils/dayAPI';
import { getOptionEndTradeTime, getOptionStartTradeTime, getOptionIntradayEndDate, getOptionIntradayStartDate, } from './optionAnalyze/getOptionContractDateTime';
import { optionAnalyzeStore } from './optionAnalyze/optionAnalyzeStore';
export const OptionStartTradeTimeSelect = memo(function OptionStartTradeTimeSelect() {
    const tradeDays = useSnapshot(staticStore).tradedDate.days.slice(0, 10);
    const intradate = useSnapshot(staticStore).tradedDate.intraday;
    const intradayStartTime = getOptionIntradayStartDate(intradate);
    const startDateTime = getOptionStartTradeTime(tradeDays, [intradayStartTime]);
    const state = useSnapshot(optionAnalyzeStore);
    const data = startDateTime?.map((s, index) => ({
        value: dayAPI(s).unix(),
        label: dayAPI(s).format('MM/DD(dd) HH:mm'),
    })) ?? [];
    return (<div css={fill}>
      <Select label='起始區間' value={dayAPI(state.contractStartDateTime).unix()} onChange={date => {
            optionAnalyzeStore.contractStartDateTime = dayAPI(Number(date) * 1000).clone();
        }} data={data}/>
    </div>);
});
export const OptionEndTradeTimeSelect = memo(function OptionEndTradeTimeSelect() {
    const tradeDays = useSnapshot(staticStore).tradedDate.days.slice(0, 10);
    const intradate = useSnapshot(staticStore).tradedDate.intraday;
    const intradayEndTime = getOptionIntradayEndDate(intradate);
    const endDateTime = getOptionEndTradeTime(tradeDays, [intradayEndTime]);
    const state = useSnapshot(optionAnalyzeStore);
    useEffect(() => {
        optionAnalyzeStore.intradayEndTime = intradayEndTime;
    }, [intradate]);
    const startDateTime = state.contractStartDateTime;
    const data = endDateTime?.map((s, index) => ({
        value: dayAPI(s).unix(),
        label: dayAPI(s).format('MM/DD(dd) HH:mm'),
    })) ?? [];
    const foolproofData = data.filter(s => s.value > startDateTime.unix());
    return (<div css={fill}>
      <Select label='結束區間' value={dayAPI(state.intradayEndTime).unix()} onChange={date => {
            optionAnalyzeStore.intradayEndTime = dayAPI(Number(date) * 1000).clone();
        }} data={foolproofData}/>
    </div>);
});
