import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { memo, useEffect, useState } from 'react';
import { Line, LineChart, ResponsiveContainer, YAxis } from 'recharts-new';
import { apirc } from '~/configs/apirc';
import { Select } from '@mantine/core';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, flex, jc, } from '~/modules/AppLayout/FlexGridCss';
const intervalArray = ['15K', '60K'];
const symbolsTranslation = {
    TX: '台指',
    TWN: '富台',
    YM: '小道瓊',
    NQ: '小那斯達',
    ES: '小SP',
    RTY: '小羅素',
    NK: '小日經',
    A50: 'A50',
    IN: '印度',
    CL: '輕原油',
    GC: '黃金',
    HG: '高級銅',
    SB: '11號糖',
};
const symbolsTranslationArray = Object.entries(symbolsTranslation).map(([key, value]) => ({
    value: key,
    label: value,
}));
export const Pivots = memo(function Pivots() {
    const [data, setData] = useState();
    const [intervals, setIntervals] = useState(intervalArray[0]);
    const [symbols, setSymbols] = useState(symbolsTranslationArray[0].value);
    const [updatedAnimation, setUpdatedAnimation] = useState(false);
    /** request API商品、週期 */
    const request = symbols + '_' + intervals;
    useEffect(() => {
        setUpdatedAnimation(true);
        setTimeout(() => {
            setUpdatedAnimation(false);
        }, 1000);
    }, [intervals, symbols]);
    useEffect(() => {
        const fetchData = () => {
            const getdata = apirc.marketDataAPI.pivotData
                .fetch({
                symbolStringWithTimeframe: request,
            })
                .then(result => {
                if (result) {
                    setData(result);
                }
            });
        };
        setUpdatedAnimation(true);
        // 初始加载数据
        fetchData();
        // 每10秒获取一次数据
        const interval = setInterval(fetchData, 10000);
        // 组件卸载时清除定时器
        return () => {
            clearInterval(interval);
            setUpdatedAnimation(false);
        };
    }, [request]);
    /** 盤勢狀態 */
    const trendType = data?.type;
    /** 區域上色 */
    const action = data?.action;
    /** 現在價格 */
    const current = data?.current;
    /** 過去價格4個時間 */
    const pivot = data?.pivot;
    /** 餵給chart的data */
    const chartData = pivot?.concat([current]);
    //給chart用的  利用自建的'最高、最低'當作圖表最大上下限
    const maxValue = Math.max(...(chartData?.map(datum => datum.price) || [])) * 1.0005;
    const minValue = Math.min(...(chartData?.map(datum => datum.price) || [])) * 0.9995;
    const yDomain = [maxValue, minValue];
    //自動延伸 外層
    const priceRange = maxValue - minValue;
    //將pivot與自製的上下限合併
    const mergePrice = pivot?.map(s => s.price)?.concat(yDomain);
    //圖表對應的價格
    const priceValue = mergePrice
        ?.map(s => Number(s.toFixed(2)))
        ?.sort((a, b) => Number(b) - Number(a)) ?? [0, 0, 0, 0, 0, 0];
    const row1 = ((priceValue[0] - priceValue[1]) / priceRange) * 100;
    const row2 = ((priceValue[1] - priceValue[2]) / priceRange) * 100;
    const row3 = ((priceValue[2] - priceValue[3]) / priceRange) * 100;
    const row4 = ((priceValue[3] - priceValue[4]) / priceRange) * 100;
    const row5 = ((priceValue[4] - priceValue[5]) / priceRange) * 100;
    const priceRowHieght = css `
    grid-template-rows: ${row1.toFixed(2)}% ${row2.toFixed(2)}% ${row3.toFixed(2)}% ${row4.toFixed(2)}% ${row5.toFixed(2)}%;
  `;
    return (<styleds.container>
      {/* 選擇器 */}
      <Header type={trendType} intervals={intervals} symbols={symbols} setIntervals={setIntervals} setSymbols={setSymbols}/>
      {/* 標題 */}
      <Title />
      {/* 主體 */}
      <styleds.body updatedAnimation={updatedAnimation}>
        {/* 價格區域背景 */}
        <styleds.infoBackgroundContainer>
          {/* 左側顯示價格區域 */}
          <styleds.infoRightContent css={priceRowHieght}>
            {priceValue.slice(1, -1)?.map((s, index) => {
            return (<styleds.priceLine key={index}>
                  <styleds.priceLable>{s}</styleds.priceLable>
                </styleds.priceLine>);
        })}
          </styleds.infoRightContent>
          {/* 右側顯示周支撐力顏色狀態 */}
          <styleds.infoLeftContent css={priceRowHieght}>
            {action?.map((s, index) => {
            return (<styleds.space action={s} key={index}></styleds.space>);
        })}
          </styleds.infoLeftContent>
        </styleds.infoBackgroundContainer>
        {/* 圖表 */}
        <styleds.chartContent>
          <Chart data={chartData ?? []} yDomain={yDomain}/>
        </styleds.chartContent>
      </styleds.body>
    </styleds.container>);
});
const Header = memo(function Header(props) {
    return (<styleds.headerContainer>
      <span>{props.type}</span>

      <div css={flex.h.default}>
        <Select size='xs' data={symbolsTranslationArray} value={props.symbols} onChange={(symbol) => {
            props.setSymbols(symbol);
        }} css={css `
            width: 80px;
            .mantine-Select-input {
              font-size: 14px;
            }
          `}/>
        <Select size='xs' data={intervalArray} value={props.intervals} onChange={(interval) => {
            props.setIntervals(interval);
        }} css={css `
            width: 64px;
            .mantine-Select-input {
              font-size: 14px;
            }
          `}/>
      </div>
    </styleds.headerContainer>);
});
const Title = memo(function Title() {
    return (<styleds.titleContainer>
      <LegendStyled.Container>
        <div>
          <LegendStyled.LegendItem barFill={'#eb483f'}/>
          偏多
        </div>
        <div>
          <LegendStyled.LegendItem barFill={'#33bb33'}/>
          偏空
        </div>
        <div>
          <LegendStyled.LegendItem barFill={'#e7e757'}/>
          觀察
        </div>
      </LegendStyled.Container>
      <span>目前</span>
      <span>轉折</span>
    </styleds.titleContainer>);
});
const Chart = memo(function Chart(props) {
    return (<ResponsiveContainer width='100%' height='100%'>
        <LineChart data={props.data} margin={{
            top: 0,
            right: 72,
            left: 56,
            bottom: 0,
        }}>
          <Line type='linear' dataKey='price' stroke='#5ea3ee' isAnimationActive={false} strokeWidth={1.6}/>

          <YAxis domain={props.yDomain} tick={false} axisLine={false} mirror={true} ticks={props.yDomain}/>
        </LineChart>
      </ResponsiveContainer>);
});
const fadeIn = keyframes `
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    transition: 1.3s;
  `,
    headerContainer: styled.div `
    ${fill_horizontal_cross_center};
    ${jc.spaceBetween};
    height: 36px;
    width: 100%;
    background-color: #eaeaea;
    border-radius: 6px;
    padding: 0 8px;
  `,
    titleContainer: styled.div `
    display: grid;
    grid-template-columns: calc(100% - 100px) 50px 50px;
    width: 100%;
    height: 24px;
    border-bottom: 1px solid #eaeaea;
    font-size: 12px;
    & > span {
      display: flex;
      direction: row;
      justify-content: center;
      align-items: center;
    }
  `,
    body: styled.div `
    position: relative;
    height: calc(100% - 60px);
    width: 100%;
    animation: ${props => props.updatedAnimation === true && fadeIn} 1.5s;
  `,
    infoBackgroundContainer: styled.div `
    ${fill_horizontal_all_center};
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 2px;
  `,
    infoRightContent: styled.div `
    display: grid;
    width: calc(100% - 100px);
    height: 100%;
  `,
    infoLeftContent: styled.div `
    display: grid;
    width: 100px;
    height: 100%;
    grid-template-columns: 50% 50%;
  `,
    chartContent: styled.div `
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 4px;
  `,
    priceLine: styled.div `
    ${fill_horizontal_all_center};
    justify-content: start;
    align-items: end;
    border-bottom: 1px solid #aaaaaa;
    &:first-child {
      border-top: 1px solid #00000000;
    }
    &:last-child {
      border-bottom: 1px solid #aaaaaa;
    }
  `,
    priceLable: styled.div `
    margin-bottom: -10px;
    background-color: #dbdbdb;
    border: 1px solid #acacac;
    z-index: 300;
    font-size: 10px;
    border-radius: 4px;
    padding: 0 4px;
    cursor: pointer;
    &:hover {
      background-color: #f0f0f0;
      z-index: 900;
    }
  `,
    space: styled.div `
    border-bottom: 1px solid #aaaaaa;
    border-left: 1px solid #aaaaaa;

    &:nth-child(1) {
      border-top: 1px solid #00000000;
    }
    &:nth-child(2) {
      border-top: 1px solid #00000000;
    }

    &:nth-child(9) {
      border-bottom: 1px solid #00000000;
    }
    &:nth-child(10) {
      border-bottom: 1px solid #00000000;
    }

    ${option => {
        const long = option.action === '1' &&
            css `
          background-color: #eb483f;
        `;
        const short = option.action === '-1' &&
            css `
          background-color: #33bb33;
        `;
        const swing = option.action === '0' &&
            css `
          background-color: #ffffaa;
        `;
        const none = option.action === '' &&
            css `
          background-color: #aaaaaa55;
        `;
        return css([long, short, swing, none]);
    }}
  `,
};
const LegendStyled = {
    Container: styled.div `
    ${fill_horizontal_cross_center};
    font-size: 12px;
    line-height: 20px;
    & > * {
      ${fill_horizontal_all_center};
      width: 24%;
    }
  `,
    LegendItem: styled.div `
    width: 8px;
    height: 8px;
    border-radius: 50px;
    background-color: ${options => options.barFill};
  `,
};
