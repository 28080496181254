import { Select } from '@mantine/core';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { fill } from '~/modules/AppLayout/FlexGridCss';
import { optionAnalyzeStore } from './optionAnalyze/optionAnalyzeStore';
export const OptionContractSelect = memo(function OptionContractSelect() {
    const state = useSnapshot(optionAnalyzeStore);
    const allContract = state.allContract;
    const defaultContract = state.currentContract;
    /** API回傳的所有契約 再將格式餵給mantine */
    const allContractArray = allContract?.map((s, index) => ({
        value: s,
        label: s,
    })) ?? [];
    /** 將API回傳已過期的契約篩選掉 */
    // const contractArray = allContractArray.filter(
    //   s => dayAPI(s.value.slice(0, 6)) >= dayAPI().subtract(7, 'day'),
    // )
    console.log('合約選擇:', state.currentContract, allContractArray);
    return (<div css={fill}>
      <Select label='合約選擇' value={defaultContract} onChange={(contract) => {
            optionAnalyzeStore.currentContract = contract;
        }} data={allContractArray}/>
    </div>);
});
