import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { store } from '~/pages/heineken_template/_private/store';
import { Preset_LoginPageOfCarousel } from '../heineken_template/_preset/preset_LoginPageOfCarousel';
import { ChipsDashboard } from './chips/ChipsDashboard';
import { Stanli_Menu } from './component/stanli_Menu';
import { StockMonitors } from './monitor/StockMonitors';
import { OptionChartView } from './option/OptionChartView';
import { SidePane } from './quote/SidePane';
import { stanli_initStrategies, stanli_strategyGroup } from './stanli_initStrategies';
import { stanli_initStyling } from './stanli_initStyling';
import { Stanli_Topbar } from './stanli_Topbar';
import { useMount } from 'react-use';
export const stanli_init = {
    global(templateProps) {
        useThemeStore.setState({ theme: 'light' });
        store.charting.setThemeMode('light');
        templateProps.layout.Charting = undefined;
        templateProps.layout.Drawer1 = Stanli_Menu;
        templateProps.layout.Drawer2 = SidePane;
    },
    indexPage(templateProps) {
        stanli_initStyling(templateProps);
        stanli_initStrategies();
        templateProps.layout.login = (<Preset_LoginPageOfCarousel resources={[{ image: [true, '/stanli/loginView.jpeg'] }]}/>);
        templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web');
        templateProps.hooks.add(() => {
            useMount(() => {
                store.charting.widgetOptions = {
                    ...store.charting.widgetOptions,
                    enableVolumeIndicator: false,
                    disabledHeaderWidget: false,
                    disabledLeftToolbar: false,
                    disabledTimeframesToolbar: true,
                    disabledHeaderChartType: true,
                    disabledHeaderCompare: true,
                    disabledGoToDate: true,
                    disabledHeaderSaveload: true,
                    overrides: store.charting.lightOverrides,
                };
                store.charting.setStrategy(stanli_strategyGroup.main[0]);
                store.charting.updateFromStrategyState();
            });
        });
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 200px 336px 1fr;
      gap: 4px;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Drawer1 Drawer2 Chart'
        'Drawer1 Drawer2 Chart';

      .Chart {
        padding: 4px;
        box-shadow: 0 0 6px 2px #cccccc;
        border-radius: 8px;
      }

      ${createIPadCss(css `
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart';
      `)}
    `;
        templateProps.layout.Row1 = Stanli_Topbar;
        templateProps.layout.Drawer1 = Stanli_Menu;
        templateProps.layout.Drawer2 = SidePane;
    },
    ['option/index.page'](templateProps) {
        stanli_init.global(templateProps);
        stanli_initStyling(templateProps);
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 200px 336px 1fr;
      place-content: flex-start;
      gap: 4px;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Drawer1 Drawer2 Col2'
        'Drawer1 Drawer2 Col2';

      ${createIPadCss(css `
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col2 Col2 Col2';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col2 Col2 Col2';
      `)}
    `;
        templateProps.layout.Row1 = Stanli_Topbar;
        templateProps.layout.Drawer1 = Stanli_Menu;
        templateProps.layout.Drawer2 = SidePane;
        templateProps.layout.Col2 = OptionChartView;
    },
    ['chips/index.page'](templateProps) {
        stanli_initStrategies();
        templateProps.hooks.add(() => {
            useMount(() => {
                store.charting.widgetOptions = {
                    ...store.charting.widgetOptions,
                    enableVolumeIndicator: false,
                    disabledHeaderWidget: true,
                    disabledLeftToolbar: false,
                    disabledTimeframesToolbar: true,
                    disabledHeaderChartType: true,
                    disabledHeaderCompare: true,
                    disabledGoToDate: true,
                    disabledHeaderSaveload: true,
                    overrides: store.charting.lightOverrides,
                };
                store.charting.setStrategy(stanli_strategyGroup.chips[0]);
                store.charting.updateFromStrategyState();
            });
        });
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 200px 336px 1fr;
      place-content: flex-start;
      gap: 4px;

      .Chart {
        padding: 4px;
        box-shadow: 0 0 6px 2px #cccccc;
        border-radius: 8px;
      }

      grid-template-areas:
        'Row1 Row1 Row1'
        'Drawer1 Drawer2 Chart'
        'Drawer1 Drawer2 Chart';

      ${createIPadCss(css `
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart';
      `)}
    `;
        templateProps.layout.Row1 = Stanli_Topbar;
        templateProps.layout.Drawer1 = Stanli_Menu;
        templateProps.layout.Drawer2 = ChipsDashboard;
    },
    ['monitor/index.page'](templateProps) {
        stanli_init.global(templateProps);
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 200px 336px 1fr;
      place-content: flex-start;
      gap: 4px;

      .Col2 {
        padding: 4px;
        box-shadow: 0 0 6px 2px #cccccc;
        border-radius: 8px;
      }

      grid-template-areas:
        'Row1 Row1 Row1'
        'Drawer1 Drawer2 Col2'
        'Drawer1 Drawer2 Col2';

      ${createIPadCss(css `
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col2 Col2 Col2';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col2 Col2 Col2';
      `)}
    `;
        templateProps.layout.Row1 = Stanli_Topbar;
        templateProps.layout.Col2 = StockMonitors;
        templateProps.layout.Drawer1 = Stanli_Menu;
        templateProps.layout.Drawer2 = SidePane;
    },
};
